<template>
    <div>
        <div v-if="lang === 'en'">
            <b-container>
                <p class="mt-5"><b><u><span style='font-size:20.0pt;line-height:107%'>PRIVACY
                POLICY - LIVES</span></u></b></p>

                <p class=MsoNormal><b><u><span style='font-size:20.0pt;line-height:107%'>Information
                on the processing and protection of personal data of the Lives application</span></u></b></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>The Lives mobile application respects your
                privacy and at the same time offers its users GPS location, dating and mapping
                events.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>If you decide to use our services, you
                entrust us with your information. We understand that this is a great
                responsibility and we work hard to protect your information.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>This privacy policy is intended to help you
                understand what information we collect, why we collect it, and how you can
                update, manage, and delete your information. This privacy policy includes:</span></p>

                <p class=MsoListParagraphCxSpFirst style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>I. Legal framework of Lives application processing</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>II. Lives personal data manager</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>III. Accuracy of personal data in the Lives application</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>IV. The purposes of location recording, processing and the legal
                basis for the processing of personal data in the Lives application</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>V. Lives Data Collection</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>VI. Lives storage time</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>VII. Categories of recipients of personal data in the Lives
                application</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>VIII. The right to access personal data in the Lives application</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>IX. The right to delete personal data in the Lives application</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>X. The right to restrict the processing of personal data in the
                Lives application</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>XI. The right to the transferability of personal data in the Lives
                application</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>XII. The right to object to the processing of personal data in the
                Lives application</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>XIII. The right to withdraw consent to the processing of personal
                data in the Lives application</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>XIV. Exercising rights in the Lives application</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>XV. Lives Commissioner</span></b></p>

                <p class=MsoListParagraphCxSpLast style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>XVI. Right to file a complaint</span></b></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>In this Privacy Policy, the term “personal
                information” means information that you provide to us that personally
                identifies you, such as your name, email address or billing information, and in
                certain circumstances, your location and information associated with your
                location, or other data that may reasonably be associated with such information
                by Lives.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Please note that when you use our Services,
                Lives will collect and use your information (as described below) in accordance
                with this Privacy Policy.</span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>I. Legal
                framework of Lives application processing</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Personal data of users and other persons in
                the Lives application (hereinafter also &quot;data subject&quot;), whose
                personal data and location recording of the mobile application
                &quot;Lives&quot; of the company Social technology s.r.o. are processed in
                accordance with applicable law. In particular Regulation (EU) No 2016/679 of
                the European Parliament and of the Council on the protection of individuals
                with regard to the processing of personal data and on the free movement of such
                data and repealing Directive 95/46 / EC (General Data Protection Regulation).</span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>II.
                Lives personal data manager</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>The administrator of the personal data
                obtained in the Lives application is the company Social Technology s.r.o., ID
                number 07213271, with its registered office in Prostějov - Wolfova 3609/23,
                postal code 79603, (hereinafter also the &quot;administrator&quot;).</span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>III.
                Accuracy of personal data in the Lives application</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>The data subject is obliged to state his /
                her personal data (eg when registering, in his / her user account, when
                purchasing advanced functionalities) correctly and truthfully.</span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>IV. The
                purposes of location recording, processing and the legal basis for the
                processing of personal data in the Lives application</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>A. Name, surname or any other user-chosen
                identification for registration at both FO and PO, which contain information
                such as relationship, orientation, children, housing, education, address,
                residence or registered office, ID number, VAT number, telephone number and
                details of users, any purchases made, including recording the location of users
                carrying the information are processed for the purpose of fulfilling the
                contract (eg user identification, purchase of user advanced profile, analysis
                of inappropriate post or profile reports, sharing and location recording within
                proper application functionality); the legal basis for the processing of such
                data for that purpose is their necessity to fulfill the contractual terms and
                conditions to which the data subject is a party or to take measures taken
                before the contractual terms are confirmed at the request of the data subject.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>&nbsp;</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>The above personal data incl. location
                records are further processed after the agreement of the contractual
                conditions, if this is necessary for the fulfillment of a legal obligation or
                the smooth operation of the Lives application, which is applied to the company
                Social technology s.r.o. applies.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>B. Personal data incl. location sharing
                under letter A. data on the history made by the user, purchases made and
                complaints made, or location records, may be processed for the purpose of
                maintaining a profile account; the legal basis for the processing of this data
                for this purpose is the consent given by the user to the terms and conditions
                of the Lives application.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>&nbsp;</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>C. Personal data of users referred to in
                letter A. they can be processed for information as well as marketing purposes,
                ie displaying advertising banners, sending updates and new functionalities of
                the Lives mobile application of the company Social technology s.r.o ..; the
                legal basis for the processing of this data in this case is the legitimate
                interest of Social technology s.r.o. and informing about the development of the
                Lives application.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>D. Recording the user's location in the
                Lives application under letter A. it is an essential component for proper
                operation and providing full functionality of the application. If location
                logging is disabled in Lives, location data will be unshared and private; the
                legal basis for the processing of this data for this purpose is the consent
                given by the user to the terms and conditions of the Lives application.</span></p>

                <p class=MsoNormal><span style='font-size:18.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>V. Lives Data Collection</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>When using the Services, your approximate
                location (if you choose to share your location) and other information you may
                choose to contribute will be visible to other users associated with the common
                username &quot;Lives&quot;.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Remember that you will need to register your
                account to access certain features of the Services (such as creating events,
                sharing location, or communicating with other users).</span></p>

                <p class=MsoNormal><b><span style='font-size:12.0pt;line-height:107%;
                font-family:"Segoe UI",sans-serif;color:black'>A. If you register your account:</span></b></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Lives will link all your information to your
                registered account. This includes information received from all devices you
                choose to sign in to.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>However, as explained in the &quot;Visibility&quot;
                section below, your account and the information associated with your account
                will only be visible to Lives.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>As you use the Services, your approximate
                location and other information you may choose to contribute will be visible to
                other users associated with this account.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Your login details are your responsibility.
                You are fully responsible for any use or misuse of your account and personal
                information as a result of passing on your login information to someone else.
                You must keep your login information completely confidential and you must not
                disclose it to others. If you set a password, make sure you change it
                frequently and at least once every six months.</span></p>

                <p class=MsoNormal><b><span style='font-size:12.0pt;line-height:107%;
                font-family:"Segoe UI",sans-serif;color:black'>B. Information to be collected</span></b></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>We want you to understand the types of
                information we collect when you use our services</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Lives gathers information to provide better
                services to all its users - from locating your location to creating events to
                keep track of what events or who is in your area. The information that Lives
                collects and how that information is used depends on how you choose to use our
                services and how you manage your privacy control.</span></p>

                <p class=MsoNormal><b><span style='font-size:12.0pt;line-height:107%;
                font-family:"Segoe UI",sans-serif;color:black'>C. Visibility</span></b></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Please note that your username and other
                information you have chosen on your personal profile will be visible to others.
                Note that your username will be included along with any information you submit.
                So you will be visible to others. Also, your Lives friends will see your name
                and profile photo and other information you choose to share with them.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>If you want to remain invisible on the map
                of Lives, you can set the Lives application not to show your location on the
                map. To do so, just use the button on the main screen called &quot;visibility
                to other users&quot;. If enabled, some features may not be available. If you
                make this choice while &quot;invisible&quot; to other users on the map, your
                location information will continue to be recorded for the purposes set forth in
                this Privacy Policy below.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Your location information is collected using
                a GPS signal (in combination with a timestamp) or on any device on which the
                application is installed and activated. The mentioned location information is
                stored in the history.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>The types of location data we collect depend
                in part on your device and account settings. For example, depending on the
                settings of your selected device or your account settings, we may also collect
                information about your location when you are not using the app, so that we can
                tell you which of your favorite contacts are near and nearby, or the type of
                event. The one you are currently interested in is in your area and you have a
                perfect overview.</span></p>

                <p class=MsoNormal><b><span style='font-size:12.0pt;line-height:107%;
                font-family:"Segoe UI",sans-serif;color:black'>D. Why Lives collects data about
                your location:</span></b></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Lives may use information collected from you
                or provided by you for the following purposes:</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• Improving and correcting Lives services</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• Notification of notifications regarding
                nearby contacts</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• Notification of notifications regarding an
                event in the area</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• Improve accuracy and correct map data
                errors</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• Location sharing to meet new users</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• Improving GPS location</span></p>

                <p class=MsoNormal><b><span style='font-size:12.0pt;line-height:107%;
                font-family:"Segoe UI",sans-serif;color:black'>E. Why Lives collects other
                information data:</span></b></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• Improving and correcting Lives services</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• We also use your information to ensure
                that our services function as intended, such as monitoring outages or
                troubleshooting you report to us. And we use your information to improve the
                service or to develop new features or services.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• When reporting inappropriate content that
                affects not only users but also events.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• To provide support, feedback and handle
                complaints and requests.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• To display ads</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• To analyze the use of the Lives
                application, to understand how the application is successful, which functions
                are most often used or not used, etc.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• For sending updates, notifications, but
                also information related to services.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• For conducting surveys and questionnaires;
                And if you contact Lives, we can keep a record of your request to help you
                resolve any issues you may have.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• Based on your prior consent (to the extent
                required by applicable law), we may also use the email address you provide to
                send you promotional and / or marketing materials. You may decide at any time
                that you do not wish to receive such materials by clicking on the
                &quot;Unsubscribe&quot; link in the emails we may send you. In any case, Lives
                will not share your contact information with any advertiser unless expressly
                permitted or required by law.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• To protect Lives, our users, the public
                and for legal reasons</span></p>

                <p class=MsoNormal><b><span style='font-size:12.0pt;line-height:107%;
                font-family:"Segoe UI",sans-serif;color:black'>Lives does not sell, rent or
                lease your personal information to third parties.</span></b></p>

                <p class=MsoNormal><span style='font-size:18.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>VI. Lives storage time</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Personal data of the Lives application will
                be stored by Social technology s.r.o. for the period required by the applicable
                legal regulations, unless the period is so determined for the period during
                which the legal reason for processing lasts or for the time necessary for the
                specified exercise or defense of legal claims.</span></p>

                <p class=MsoNormal><span style='font-size:18.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>VII. Categories of recipients of personal
                data in the Lives application:</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>By processing the personal data of the Lives
                application, the company Social technology s.r.o. entrust a third party as a
                processor.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• Post-implementation time of the project</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>The administrator is entitled to provide all
                personal data mentioned in Article IV for the purpose of service support,
                monitoring, report, maintenance. the entity providing the operation and regular
                maintenance of the application.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• Providers of accounting tax and legal
                services</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>The administrator is also entitled to
                provide personal data to persons providing accounting, tax and legal services
                to the administrator for the purpose of fulfilling its legal obligations and in
                cases where it is necessary for the determination, exercise or protection of
                legal claims, whether in judicial, extrajudicial or administrative proceedings.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>• Entities providing services referred to as
                &quot;administrators&quot;.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>The administrator is entitled to provide all
                data and information according to Article IV. entities that, according to
                reports and notifications to the user of the application, will receive items to
                control all activities that go beyond the policies and policies of using the
                Lives application.</span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>VIII.
                The right to access personal data in the Lives application</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>The data subject has the right to obtain
                confirmation from the controller whether the personal data concerning him are
                or are not processed and, if so, he has the right to obtain access to this
                personal data.</span></p>

                <p class=MsoNormal><span style='font-size:18.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>IX. The right to delete personal data in the
                Lives application</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>The data subject has the right to have the
                controller delete personal data (according to the Lives terms and conditions)
                concerning the data subject, and the controller is obliged to delete the
                personal data without undue delay if any of the reasons set out in the General
                Data Protection Regulation data.</span></p>

                <p class=MsoNormal><span style='font-size:18.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>X. The right to restrict the processing of
                personal data in the Lives application</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>The data subject has the right to have the
                controller restrict the processing of his personal data, in cases provided for
                by the General Data Protection Regulation.</span></p>

                <p class=MsoNormal><span style='font-size:18.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>XI. The right to the transferability of
                personal data in the Lives application</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Under the conditions laid down in the
                General Data Protection Regulation, the data subject has the right to obtain
                personal data concerning him or her provided to the controller in a structured,
                commonly used and machine-readable format and to transfer such data to another
                controller without personal data was provided, he gave his consent.</span></p>

                <p class=MsoNormal><span style='font-size:18.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>XII. The right to object to the processing
                of personal data in the Lives application</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>The data subject has the right to object at
                any time to the processing of his or her personal data processed for the
                legitimate interest of the controller, including profiling based on the legitimate
                interest of the controller. Where personal data are processed for the purposes
                of direct marketing, the data subject shall have the right at any time to
                object to the processing of personal data concerning him or her for that
                marketing, which shall include profiling as regards such direct marketing.</span></p>

                <p class=MsoNormal><span style='font-size:18.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>XIII. The right to withdraw consent to the
                processing of personal data in the Lives application</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>The data subject has the right to withdraw
                the consent to the processing of personal data at any time. Withdrawal of
                consent shall not affect the lawfulness of processing based on the consent
                given before its withdrawal. The consent can be revoked via e-mail by a notice
                sent to the e-mail address: infosoc.tech@gmail.com, by a written notice sent to
                the administrator's registered office, or to the administrator's data box (ID:
                49zaytz).</span></p>

                <p class=MsoNormal><span style='font-size:18.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>XIV. Exercising rights in the Lives
                application</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>The data subject may exercise the above
                rights via e-mail by a notice sent to the e-mail address:
                infosoc.tech@gmail.com, by a written notice sent to the administrator's
                registered office, or to the administrator's data box (ID: 49zaytz).</span></p>

                <p class=MsoNormal><span style='font-size:18.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>XV. Lives Commissioner</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>The administrator has not appointed a data
                protection officer at this time.</span></p>

                <p class=MsoNormal><span style='font-size:18.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>XVI. The right to file a complaint</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>The data subject has the right to lodge a
                complaint with the supervisory authority, in particular in the EU Member State
                of his or her habitual residence, place of employment or place of the alleged
                breach, if he or she considers that the processing of his or her personal data
                infringes the General Data Protection Regulation. In the Czech Republic, the
                supervisory authority is the Office for Personal Data Protection.</span></p>

                <p class=MsoNormal align=right style='text-align:right'><span style='font-size:
                12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;color:black'>April 10,
                2022</span></p>

            </b-container>
        </div>
        <div v-else>
        <b-container>
        <b-row class="mb-5 mt-5 text-left">
            <b-col>
                <p class=MsoNormal><b><u><span style='font-size:20.0pt;line-height:107%'>PRIVACY
                POLICY – LIVES</span></u></b></p>

                <p class=MsoNormal><b><u><span style='font-size:20.0pt;line-height:107%'>Informace
                o zpracování a ochraně osobních údajů aplikace Lives </span></u></b></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Mobilní aplikace Lives respektuje vaše
                soukromí a zároveň nabízí svým uživatelům GPS lokalizování, seznamování a
                vytváření událostí na mapovém podkladu.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Jestliže se rozhodnete používat naše služby,
                svěřujete nám své informace. Chápeme, že je to velká odpovědnost a tvrdě
                pracujeme na ochraně vašich informací.</span></p>

                <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Tyto zásady ochrany osobních údajů vám mají
                pomoci porozumět tomu, jaké informace shromažďujeme, proč je shromažďujeme a
                jak můžete své údaje aktualizovat, spravovat a mazat. Tyto zásady ochrany
                osobních údajů zahrnují:</span></p>

                <p class=MsoListParagraphCxSpFirst style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>I.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Právní rámec zpracování aplikace Lives</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>II.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Správce osobních údajů aplikace Lives</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>III.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Přesnost osobních údajů v&nbsp;aplikaci
                Lives</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>IV.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Účely zaznamenání polohy, zpracování a
                právní základ zpracování osobních údajů aplikace Lives</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>V.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Shromažďování dat aplikace Lives</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>VI.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Doba uložení v&nbsp;aplikaci Lives</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>VII.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Kategorie příjemců osobních údajů
                v&nbsp;aplikaci Lives</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>VIII.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Právo na přístup k osobním údajům
                v&nbsp;aplikaci Lives</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>IX.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Právo na výmaz osobních údajů v aplikaci
                Lives</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>X.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>. Právo na omezení zpracování osobních údajů
                v aplikaci Lives</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>XI.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Právo na přenositelnost osobních údajů
                v&nbsp;aplikaci Lives</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>XII.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Právo vznést námitku proti zpracování
                osobních údajů v&nbsp;aplikaci Lives</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>XIII.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Právo odvolat souhlas se zpracováním
                osobních údajů v&nbsp;aplikaci Lives</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>XIV.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Uplatnění práv v&nbsp;aplikaci Lives</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>XV.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Pověřenec aplikace Lives</span></b></p>

                <p class=MsoListParagraphCxSpLast style='margin-left:54.0pt;text-indent:-36.0pt'><b><span
                style='font-size:12.0pt;line-height:107%;font-family:"Segoe UI",sans-serif;
                color:black'>XVI.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:12.0pt;line-height:107%;font-family:
                "Segoe UI",sans-serif;color:black'>Právo podat stížnost</span></b></p>

                <p class=MsoNormal style='margin-left:18.0pt'><span style='font-size:12.0pt;
                line-height:107%;font-family:"Segoe UI",sans-serif;color:black'>V těchto
                Zásadách ochrany osobních údajů pojem „osobní údaje“ znamená informace, které
                nám poskytnete a které vás osobně identifikují, jako je vaše jméno, e-mailová
                adresa nebo fakturační údaje, a za určitých okolností vaše poloha a informace spojeny
                s&nbsp;vaší polohou, nebo jiné údaje, které mohou být přiměřeně spojeny s
                takovými informacemi ze strany Lives.</span></p>

                <p class=MsoNormal style='margin-left:18.0pt'><span style='font-size:12.0pt;
                line-height:107%;font-family:"Segoe UI",sans-serif;color:black'>Vezměte prosím
                na vědomí, že když používáte naše Služby, Lives bude shromažďovat a používat
                vaše informace (jak je popsáno níže), v souladu s těmito Zásadami ochrany
                osobních údajů.</span></p>

                <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;
                color:black'>I. Právní rámec zpracování aplikace Lives</span></p>

                <p class=MsoNormal style='margin-bottom:22.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
                color:black'>Osobní údaje uživatelů a dalších osob v&nbsp;aplikaci Lives (dále
                též „subjekt údajů“), jejichž osobní údaje a zaznamenání polohy mobilní aplikace
                „Lives“ společnosti Social technology s.r.o. zpracovává, jsou zpracovávány v
                souladu s platnými právními předpisy. Zejména s nařízením Evropského parlamentu
                a Rady (EU) č. 2016/679, o ochraně fyzických osob v souvislosti se zpracováním
                osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES
                (obecné nařízení o ochraně osobních údajů).</span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>II.
                Správce osobních údajů aplikace Lives</span></p>

                <p class=MsoNormal style='margin-bottom:22.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
                color:black'>Správcem získaných osobních údajů v&nbsp;aplikaci Lives je
                společnost Social Technology s.r.o., IČO 07213271, se sídlem Prostějov – Wolfova
                3609/23, PSČ 79603, (dále též „správce“).</span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>III.
                Přesnost osobních údajů v&nbsp;aplikaci Lives</span></p>

                <p class=MsoNormal style='margin-bottom:22.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
                color:black'>Subjekt údajů je povinen své osobní údaje (např. při registraci,
                ve svém uživatelském účtu, při nákupu pokročilých funkcionalit) uvádět správně
                a pravdivě.</span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>IV.
                Účely zaznamenání polohy, zpracování a právní základ zpracování osobních údajů
                aplikace Lives</span></p>

                <ol style='margin-top:0cm' start=1 type=A>
                <li class=MsoNormal style='color:black;margin-bottom:7.5pt;line-height:normal;
                    background:#F8F8F8'><i><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif'>Jméno,
                    příjmení čí jakékoliv jiné uživatelsky zvolené identifikování pro registraci
                    jak u FO, tak PO, které obsahují informace, jako vztah, orientace, děti,
                    bydlení, vzdělání, adresa, bydliště či sídla, IČO, DIČ, telefonní číslo a
                    údaje o uživatelích, případném uskutečněném nákupu jsou včetně zaznamenání
                    polohy uživatelů nesoucí zmíněné informace zpracovávány za účelem plnění
                    smlouvy (např. identifikace uživatele, nákup uživatelsky pokročilého
                    profilu, analyzování hlášení nevhodného příspěvku, či profilu, možnosti
                    sdílení a zaznamenání polohy v rámci správné funkčnosti aplikace); právním
                    základem zpracování těchto údajů pro uvedený účel je jejich nezbytnost pro
                    splnění smluvních podmínek a funkčnosti, jejíž smluvní stranou je subjekt
                    údajů, nebo pro provedení opatření přijatých před potvrzením smluvních
                    podmínek na žádost subjektu údajů.<br>
                    </span></i><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif'><br>
                    Výše uvedené osobní údaje vč. zaznamenání polohy jsou po odsouhlasení smluvních
                    podmínek dále zpracovávány, je-li to nezbytné pro splnění právní
                    povinnosti, nebo bezproblémového chodu aplikace Lives, která se na
                    společnost Social technology s.r.o. vztahuje.</span></li>
                <li class=MsoNormal style='color:black;margin-bottom:7.5pt;line-height:normal;
                    background:#F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif'>Osobní
                    údaje vč. sdílení polohy uvedené pod písm. A a údaje o historii po uživatelem
                    učiněné registraci, uskutečněných nákupů a uplatněných reklamací, nebo zaznamenání
                    polohy, mohou být zpracovávány pro účely vedení profilového účtu; právním
                    základem zpracování těchto údajů pro uvedený účel je souhlas udělený uživatelem
                    se smluvními podmínkami aplikace Lives.<br>
                    <br>
                    </span></li>
                <li class=MsoNormal style='color:black;margin-bottom:7.5pt;line-height:normal;
                    background:#F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif'>Osobní
                    údaje uživatelů uvedené pod písm. A mohou být zpracovávány pro účely informační,
                    ale i marketingové tj. zobrazení reklamových bannerů, zasílání aktualizací
                    a nových funkcionalit mobilní aplikace Lives společnosti Social technology
                    s.r.o..; právním základem zpracování těchto údajů je v tomto případě
                    oprávněný zájem společnosti Social technology s.r.o. a informování o vývoji
                    aplikace Lives.</span></li>
                <li class=MsoNormal style='color:black;margin-bottom:7.5pt;line-height:normal;
                    background:#F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif'>Zaznamenávání
                    polohy uživatele v&nbsp;aplikaci Lives pod písm. A je nezbytnou součástí
                    pro správný chod a poskytování plné funkčnosti aplikace. V&nbsp;případě
                    deaktivování záznamu polohy v&nbsp;aplikaci Lives budou data o poloze
                    nesdílená a soukromá; právním základem zpracování těchto údajů pro uvedený
                    účel je souhlas udělený uživatelem se smluvními podmínkami aplikace Lives.</span></li>
                </ol>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>V. Shromažďování
                dat aplikace Lives</span></p>

                <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;
                margin-left:18.0pt;line-height:normal;background:#F8F8F8'><span
                style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;color:black'>&nbsp;</span></p>

                <p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Při používání Služeb bude vaše přibližná poloha (jestliže se
                rozhodnete sdílet svou polohu) a další informace, kterými se můžete rozhodnout
                přispět, viditelné pro ostatní uživatele spojené s obecným uživatelským jménem
                „Lives“.</span></p>

                <p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Pamatujte, že pro přístup k určitým funkcím Služeb (například vytváření
                událostí, sdílení polohy nebo komunikací mezi ostatními uživateli) si budete
                muset zaregistrovat svůj účet.</span></p>

                <p class=MsoListParagraph style='margin-bottom:7.5pt;text-indent:-18.0pt;
                line-height:normal;background:#F8F8F8'><b><span style='font-size:11.5pt;
                font-family:"Segoe UI",sans-serif;color:black'>A.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Jestliže svůj účet zaregistrujete:</span></b></p>

                <p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Lives propojí všechny vaše informace s vaším registrovaným účtem.
                To zahrnuje informace přijaté ze všech zařízení, ke kterým jste se rozhodli
                přihlásit.</span></p>

                <p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Ovšem jak je vysvětleno v sekci „Viditelnost“ níže, váš účet a
                informace spojené s vaším účtem budou viditelné pouze pro Lives.</span></p>

                <p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Při používání Služeb bude vaše přibližná poloha a další informace,
                kterými se můžete rozhodnout přispět, viditelné pro ostatní uživatele spojené s
                tímto účtem.</span></p>

                <p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Vaše přihlašovací údaje jsou vaší zodpovědností. Jste plně
                odpovědní za jakékoli použití nebo zneužití vašeho účtu a osobních údajů jako v
                důsledku předání vašich přihlašovacích údajů někomu jinému. Své přihlašovací
                údaje musíte udržovat v naprosté důvěrnosti a nesmíte je sdělovat ostatním.
                Pokud nastavujete heslo, ujistěte se, že je měníte často a alespoň jednou za
                šest měsíců.</span></p>

                <p class=MsoListParagraph style='margin-bottom:7.5pt;text-indent:-18.0pt;
                line-height:normal;background:#F8F8F8'><b><span style='font-size:11.5pt;
                font-family:"Segoe UI",sans-serif;color:black'>B.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Informace, které se shromažďují</span></b></p>

                <p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Chceme, abyste rozuměli typům informací, které shromažďujeme, když
                používáte naše služby</span></p>

                <p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Lives shromažďuje informace, aby všem svým uživatelům poskytoval
                lepší služby – od lokalizování vaší polohy až po vytváření událostí, abyste měli
                přehled, jaké události, nebo kdo se právě nachází ve vašem okolí. Informace,
                které Lives shromažďuje, a jak jsou tyto informace používány, závisí na tom,
                jak se rozhodnete používat naše služby a jak spravujete kontrolu svého
                soukromí.</span></p>

                <p class=MsoListParagraph style='margin-bottom:7.5pt;text-indent:-18.0pt;
                line-height:normal;background:#F8F8F8'><b><span style='font-size:11.5pt;
                font-family:"Segoe UI",sans-serif;color:black'>C.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Viditelnost</span></b></p>

                <p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Vezměte prosím na vědomí, že vaše uživatelské jméno a další informace
                vámi zvolené na osobním profilu budou viditelné pro ostatní. Všimněte si, že
                vaše uživatelské jméno bude zahrnuto spolu se všemi informacemi, které odešlete
                k&nbsp;odeslání. Budete tedy viditelní pro ostatní. Také – vaši přátelé na Lives
                uvidí vaše jméno a profilovou fotku a další informace, které se s nimi
                rozhodnete sdílet.</span></p>

                <p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Pokud chcete zůstat na mapě Lives neviditelní, můžete aplikaci Lives
                nastavit tak, aby nezobrazovala vaši polohu na mapě. Chcete-li tak učinit, stačí
                využít tlačítko na hlavní obrazovce nazvané jako „viditelnost pro ostatní
                uživatele“. Pokud je zapnuto, některé funkce nemusí být dostupné. Jestliže
                provedete tuto volbu, zatímco budete na mapě pro ostatní uživatele
                „neviditelní“, informace o vaší poloze budou Lives nadále zaznamenány pro účely
                uvedené v těchto Zásadách ochrany osobních údajů níže.</span></p>

                <p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Informace o vaší poloze se shromažďují za pomocí GPS signálu (v
                kombinaci s časovým razítkem), nebo na jakémkoliv zařízení, na kterém je
                aplikace nainstalována a aktivována. Zmíněné informace o poloze se ukládají do
                historie.</span></p>

                <p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Typy údajů o poloze, které shromažďujeme, závisí částečně na vašem
                zařízení a nastavení účtu. Například v závislosti na nastavení vašeho vybraného
                zařízení, nebo nastavení vašeho účtu můžeme shromažďovat informace o vaší
                poloze také v době, kdy aplikaci nepoužíváte, abychom vám mohli například říci,
                který z&nbsp;vašich oblíbených kontaktů se nachází ve vašem okolí a blízkosti,
                nebo typ události, o který se právě zajímáte, je ve vašem okolí a vy tak měli
                dokonalý přehled.</span></p>

                <p class=MsoListParagraph style='margin-bottom:7.5pt;text-indent:-18.0pt;
                line-height:normal;background:#F8F8F8'><b><span style='font-size:11.5pt;
                font-family:"Segoe UI",sans-serif;color:black'>D.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Proč Lives shromažďuje data o vaší poloze:</span></b></p>

                <p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Lives může používat informace shromážděné od vás nebo vámi
                poskytnuté pro následující účely:</span></p>

                <p class=MsoListParagraphCxSpFirst style='margin-bottom:7.5pt;text-indent:-18.0pt;
                line-height:normal;background:#F8F8F8'><span style='font-size:11.5pt;
                font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Zlepšování a správnost služeb aplikace Lives</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;text-indent:
                -18.0pt;line-height:normal;background:#F8F8F8'><span style='font-size:11.5pt;
                font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Oznámení notifikací týkající se kontaktů v&nbsp;okolí</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;text-indent:
                -18.0pt;line-height:normal;background:#F8F8F8'><span style='font-size:11.5pt;
                font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Oznámení notifikací týkající se události v&nbsp;okolí</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;text-indent:
                -18.0pt;line-height:normal;background:#F8F8F8'><span style='font-size:11.5pt;
                font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Zlepšení přesnosti a oprava chyb mapových dat</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;text-indent:
                -18.0pt;line-height:normal;background:#F8F8F8'><span style='font-size:11.5pt;
                font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Sdílení polohy pro seznámení nových uživatelů</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;text-indent:
                -18.0pt;line-height:normal;background:#F8F8F8'><span style='font-size:11.5pt;
                font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Zlepšování GPS lokalizace</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;line-height:
                normal;background:#F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>&nbsp;</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;text-indent:
                -18.0pt;line-height:normal;background:#F8F8F8'><b><span style='font-size:11.5pt;
                font-family:"Segoe UI",sans-serif;color:black'>E.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
                </span></span></b><b><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Proč Lives shromažďuje ostatní informační data:</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;line-height:
                normal;background:#F8F8F8'><b><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>&nbsp;</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;text-indent:
                -18.0pt;line-height:normal;background:#F8F8F8'><span style='font-size:11.5pt;
                font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Zlepšování a správnost služeb aplikace Lives </span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;text-indent:
                -18.0pt;line-height:normal;background:#F8F8F8'><span style='font-size:11.5pt;
                font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Vaše údaje také používáme k zajištění toho, aby naše služby
                fungovaly tak, jak mají, jako je sledování výpadků nebo odstraňování problémů,
                které nám nahlásíte. A vaše údaje používáme ke zlepšení služby nebo k vývoji
                nových funkcí nebo služeb.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;text-indent:
                -18.0pt;line-height:normal;background:#F8F8F8'><span style='font-size:11.5pt;
                font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Při nahlášení nevhodného obsahu týkajícího se nejen samotných
                uživatelů, ale i událostí.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;text-indent:
                -18.0pt;line-height:normal;background:#F8F8F8'><span style='font-size:11.5pt;
                font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Pro poskytnutí podpory, zpětně vazby a vyřídili stížnosti a
                žádosti.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;text-indent:
                -18.0pt;line-height:normal;background:#F8F8F8'><span style='font-size:11.5pt;
                font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Pro zobrazení reklam</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;text-indent:
                -18.0pt;line-height:normal;background:#F8F8F8'><span style='font-size:11.5pt;
                font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Pro analýzu využívání aplikace Lives, abychom pochopili, jak je
                aplikace úspěšná, které funkce nejčastěji využívány, či naopak nevyužívány atd.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;text-indent:
                -18.0pt;line-height:normal;background:#F8F8F8'><span style='font-size:11.5pt;
                font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Pro zasílání aktualizací, oznámení, ale také informování
                související se službami.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;text-indent:
                -18.0pt;line-height:normal;background:#F8F8F8'><span style='font-size:11.5pt;
                font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Pro provádění průzkumů a dotazníků; A pokud kontaktujete Lives,
                můžeme si ponechat záznam o vaší žádosti, abychom vám pomohli vyřešit jakékoli problémy,
                se kterými se můžete potýkat.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-bottom:7.5pt;text-indent:
                -18.0pt;line-height:normal;background:#F8F8F8'><span style='font-size:11.5pt;
                font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Na základě vašeho předchozího vyjádření souhlasu (v rozsahu
                požadovaném platnými právními předpisy) můžeme e-mailovou adresu, kterou jste
                uvedli, použít také k zasílání propagačních a/nebo marketingových materiálů. Kdykoli
                se můžete rozhodnout, že takové materiály nechcete dostávat, kliknutím na odkaz
                „Odhlásit odběr“ v e-mailových zprávách, které vám můžeme zasílat. V každém
                případě Lives nebude sdílet vaše kontaktní údaje s žádným inzerentem, pokud to
                není výslovně povoleno nebo vyžadováno zákonem.</span></p>

                <p class=MsoListParagraphCxSpLast style='margin-bottom:7.5pt;text-indent:-18.0pt;
                line-height:normal;background:#F8F8F8'><span style='font-size:11.5pt;
                font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>K ochraně Lives, našich uživatelů, veřejnosti a z právních důvodů</span></p>

                <p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;background:
                #F8F8F8'><b><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;
                color:black'>Lives vaše osobní údaje neprodává, nepronajímá ani nepronajímá
                třetím stranám.</span></b></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>VI. Doba
                uložení v&nbsp;aplikaci Lives</span></p>

                <p class=MsoNormal style='margin-bottom:22.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
                color:black'>Osobní údaje aplikace Lives budou uloženy u společnosti Social
                technology s.r.o. po dobu, která je vyžadována platnými právními předpisy,
                není-li doba takto stanovena tak po dobu, po kterou trvá právní důvod
                zpracování či po dobu nezbytnou pro určený výkon nebo obhajobu právních nároků.</span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>VII.
                Kategorie příjemců osobních údajů v&nbsp;aplikaci Lives</span></p>

                <p class=MsoNormal style='margin-bottom:22.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
                color:black'>Zpracováním osobních údajů aplikace Lives může společnost Social
                technology s.r.o. pověřit třetí osobu, jakožto zpracovatele.</span></p>

                <ul style='margin-top:0cm' type=disc>
                <li class=MsoNormal style='color:black;margin-bottom:7.5pt;line-height:normal;
                    background:#F8F8F8'><i><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif'>Poimplementační
                    doba projektu<br>
                    Správce je oprávněn poskytnout za účelem servisní podpory, monitoringu,
                    reportu, údržby veškeré osobní údaje zmíněné v&nbsp;článku IV. subjektu
                    zajišťujícímu chod a pravidelnou údržbu aplikace.</span></i></li>
                <li class=MsoNormal style='color:black;margin-bottom:7.5pt;line-height:normal;
                    background:#F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif'>Poskytovatelé
                    účetních daňových a právních služeb<br>
                    Správce je oprávněn poskytnout osobní údaje také osobám poskytujícím
                    správci účetní, daňové a právní služby za účelem plnění svých zákonných
                    povinností a v případech, kdy je to nezbytné pro stanovení, výkon nebo
                    ochranu právních nároků, ať již v soudním, mimosoudním či správním řízení.</span></li>
                <li class=MsoNormal style='color:black;margin-bottom:7.5pt;line-height:normal;
                    background:#F8F8F8'><span style='font-size:11.5pt;font-family:"Segoe UI",sans-serif'>Subjekty
                    poskytující služby zvané jako „administrátor“.<br>
                    Správce je oprávněn poskytnout veškerá data a údaje dle článku IV.
                    subjektům, které budou dle hlášení a upozornění uživateli aplikace
                    dostávat podměty ke kontrole veškeré činnosti vymykající se zásadám a
                    politice užívání aplikace Lives. </span></li>
                </ul>

                <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;
                margin-left:36.0pt;line-height:normal;background:#F8F8F8'><span
                style='font-size:11.5pt;font-family:"Segoe UI",sans-serif;color:black'>&nbsp;</span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>VIII.
                Právo na přístup k osobním údajům v&nbsp;aplikaci Lives</span></p>

                <p class=MsoNormal style='margin-bottom:22.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
                color:black'>Subjekt údajů má právo získat od správce potvrzení, zda osobní
                údaje, které se ho týkají, jsou či nejsou zpracovávány, a pokud je tomu tak, má
                právo získat přístup k těmto osobním údajům.</span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>IX.
                Právo na výmaz osobních údajů v aplikaci Lives</span></p>

                <p class=MsoNormal style='margin-bottom:22.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
                color:black'>Subjekt údajů má právo na to, aby správce vymazal osobní údaje
                (dle smluvních podmínek aplikace Lives), které se daného subjektu údajů týkají,
                a správce má povinnost osobní údaje bez zbytečného odkladu vymazat, pokud je
                dán některý z důvodů stanovených obecným nařízením o ochraně osobních údajů. </span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>X. Právo
                na omezení zpracování osobních údajů v aplikaci Lives</span></p>

                <p class=MsoNormal style='margin-bottom:22.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
                color:black'>Subjekt údajů má právo na to, aby správce omezil zpracování jeho
                osobních údajů, v případech stanovených obecným nařízením o ochraně osobních
                údajů.</span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>XI.
                Právo na přenositelnost osobních údajů v&nbsp;aplikaci Lives</span></p>

                <p class=MsoNormal style='margin-bottom:22.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
                color:black'>Za podmínek stanovených obecným nařízením o ochraně osobních údajů
                má subjekt údajů právo získat osobní údaje, které se ho týkají, jež poskytl
                správci, ve strukturovaném, běžně používaném a strojově čitelném formátu, a
                právo předat tyto údaje jinému správci, aniž by tomu správce, kterému byly
                osobní údaje poskytnuty, poskytoval souhlas.</span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>XII.
                Právo vznést námitku proti zpracování osobních údajů v&nbsp;aplikaci Lives</span></p>

                <p class=MsoNormal style='margin-bottom:22.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
                color:black'>Subjekt údajů má z důvodů týkajících se jeho konkrétní situace
                právo kdykoli vznést námitku proti zpracování svých osobních údajů
                zpracovávaných z důvodu oprávněného zájmu správce, včetně profilování
                založeného na oprávněném zájmu správce. Pokud se osobní údaje zpracovávají pro
                účely přímého marketingu, má subjekt údajů právo vznést kdykoliv námitku proti
                zpracování osobních údajů, které se ho týkají pro tento marketing, což zahrnuje
                i profilování, pokud se týká tohoto přímého marketingu.</span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>XIII.
                Právo odvolat souhlas se zpracováním osobních údajů v&nbsp;aplikaci Lives</span></p>

                <p class=MsoNormal style='margin-bottom:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:black'>Subjekt
                údajů má právo kdykoliv odvolat udělený souhlas se zpracováním osobních údajů.
                Odvoláním souhlasu není dotčena zákonnost zpracování založená na souhlasu
                uděleném před jeho odvoláním. Souhlas lze odvolat prostřednictvím elektronické
                pošty oznámením zaslaným na e-mailovou adresu:&nbsp;infosoc.tech@gmail.com,
                písemným oznámením zaslaným na adresu sídla správce, či do datové schránky
                správce (ID: 49zaytz). </span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>XIV.
                Uplatnění práv v&nbsp;aplikaci Lives</span></p>

                <p class=MsoNormal style='margin-bottom:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:black'>Výše
                uvedené práva může subjekt údajů uplatnit prostřednictvím elektronické pošty
                oznámením zaslaným na e-mailovou adresu:&nbsp;infosoc.tech@gmail.com, písemným
                oznámením zaslaným na adresu sídla správce, či do datové schránky správce (ID: 49zaytz).</span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>XV.
                Pověřenec aplikace Lives</span></p>

                <p class=MsoNormal style='margin-bottom:22.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
                color:black'>Správce v&nbsp;aktuální chvíli nejmenoval pověřence pro ochranu
                osobních údajů.</span></p>

                <p class=MsoNormal style='margin-top:18.0pt;margin-right:0cm;margin-bottom:
                6.0pt;margin-left:0cm;line-height:normal;background:#F8F8F8'><span
                style='font-size:18.0pt;font-family:"Segoe UI",sans-serif;color:black'>XVI.
                Právo podat stížnost</span></p>

                <p class=MsoNormal style='margin-bottom:22.5pt;line-height:normal;background:
                #F8F8F8'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
                color:black'>Subjekt údajů má právo podat stížnost u dozorového úřadu, zejména
                v členském státě EU svého obvyklého bydliště, místa výkonu zaměstnání nebo
                místa, kde došlo k údajnému porušení, pokud se domnívá, že zpracováním jeho
                osobních údajů je porušeno obecné nařízení o ochraně osobních údajů. V České
                republice je dozorovým úřadem Úřad pro ochranu osobních údajů.</span></p>

                <p class=MsoNormal align=right style='margin-bottom:22.5pt;text-align:right;
                line-height:normal;background:#F8F8F8'><span style='font-size:12.0pt;
                font-family:"Segoe UI",sans-serif;color:black'>10. dubna 2022</span></p>

                <p class=MsoNormal>&nbsp;</p>
                            </b-col>
                        </b-row>
              </b-container>
        </div>
    </div>
</template>

<script>

export default{
    name: "Gdpr",
    computed: {
        lang(){
            return this.$i18n.locale;
        }
    }
}
</script>
